import Base from './Base'

export function createEvaluation (data) {
  return Base.post('/api/v1/evaluations/create', data)
}

export function books (data) {
  return Base.post('/api/v1/evaluations/books', data)
}
export function evaluations (data) {
  return Base.post('/api/v1/evaluations/index', data)
}
export function goods (data) {
  return Base.post('/api/v1/evaluations/goods', data)
}
export function rank (data) {
  return Base.post('/api/v1/evaluations/rank', data)
}
