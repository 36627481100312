<template>
  <div class="container">
    <scroll ref="scroll" class="scroll" :data="data">
      <div>
        <div class="header">
          <router-link to="/activity-eva" tag="div" class="back">
            <div class="ico"></div>
            <div>返回</div>
          </router-link>
        </div>
        <div class="wrapper">
          <div class="top-three">
            <div class="rank two">
              <div class="ico"></div>
              <template v-if="Object.keys(two).length>0">
                <div class="avatar">
                  <img class="img" :src="two.c_user.avatar.media_file" alt="">
                </div>
                <div class="name">{{ two.c_user.c_user_wxname }}</div>
                <div class="text">被点赞次数</div>
                <div class="num">{{ two.evaluation_good }}</div>
              </template>
            </div>
            <div class="rank one">
              <div class="ico"></div>
              <template v-if="Object.keys(one).length>0">
                <div class="avatar">
                  <img class="img" :src="one.c_user.avatar.media_file" alt="">
                </div>
                <div class="name">{{ one.c_user.c_user_wxname }}</div>
                <div class="text">被点赞次数</div>
                <div class="num">{{ one.evaluation_good }}</div>
              </template>
            </div>
            <div class="rank three">
              <div class="ico"></div>
              <template v-if="Object.keys(three).length>0">
                <div class="avatar">
                  <img class="img" :src="three.c_user.avatar.media_file" alt="">
                </div>
                <div class="name">{{ three.c_user.c_user_wxname }}</div>
                <div class="text">被点赞次数</div>
                <div class="num">{{ three.evaluation_good }}</div>
              </template>
            </div>
          </div>
          <div class="table-title">
            <div class="sort">排名</div>
            <div class="user-name">用户</div>
            <div class="good-num">点赞数</div>
            <div class="create-time">发布时间</div>
          </div>
          <div class="table-item" v-for="(item,index) in list" :key="index">
            <div class="sort">{{ index + 4 }}</div>
            <div class="user-name">{{ item.c_user.c_user_wxname }}</div>
            <div class="good-num red">{{ item.evaluation_good }}</div>
            <div class="create-time red">{{ item.create_time_new }}</div>
          </div>
        </div>
      </div>
    </scroll>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import { rank } from '@/api/Evaluations'
import { Toast } from 'vant'

export default {
  name: 'RankEva',
  components: {
    Scroll
  },
  data () {
    return {
      data: [],
      one: {},
      two: {},
      three: {},
      list: []
    }
  },
  mounted () {
    this._rank()
  },
  methods: {
    _rank () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      rank().then(res => {
        const {
          code,
          msg,
          data
        } = res
        if (code === 200) {
          this.data = data
          data.forEach((item, key) => {
            if (key === 0) {
              this.one = item
            }
            if (key === 1) {
              this.two = item
            }
            if (key === 2) {
              this.three = item
            }
            if (key >= 3) {
              this.list.push(item)
            }
          })
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.img
  width 100%
  height 100%

.container
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0

  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden
    background rgba(248, 248, 248, 1)

    .header
      width 100%
      height 458px
      bg-image2('assets/activity-evaluation/rank-header')
      background-size 100% 100%
      background-repeat no-repeat

      .back
        display flex
        align-items center
        justify-content center
        width 200px
        height 80px
        font-size 26px
        color rgba(51, 51, 51, 1)

        .ico
          width 16px
          height 28px
          margin-right 10px
          bg-image('~@/assets/img/reading/back')
          background-size 100% 100%
          background-repeat no-repeat

    .wrapper
      display table
      width 100%
      margin-top -60px
      padding-bottom 60px
      border-radius 42px 42px 0 0
      background rgba(255, 255, 255, 1)

      .top-three
        position relative
        width 670px
        height 386px
        margin -162px auto 0 auto

        .rank
          box-shadow 0 9px 51px 0 rgba(0, 0, 0, 0.04)
          border-radius 10px
          box-sizing border-box
          border-top 6px solid rgba(255, 196, 168, 1)
          background rgba(255, 255, 255, 1)

          .ico
            width 46px
            height 42px
            margin-top 20px
            margin-left 28px

          .avatar
            width 128px
            height 128px
            margin -18px auto 0 auto
            border-radius 50%
            overflow hidden

          .name
            width 100%
            height 28px
            padding 0 20px
            box-sizing border-box
            text-align center
            font-size 28px
            color rgba(0, 0, 0, 1)
            font-weight bold
            no-wrap()

          .text
            width 100%
            height 24px
            margin-top 14px
            text-align center
            font-size 24px
            color rgba(102, 102, 102, 1)

          .num
            width 100%
            height 46px
            margin-top 20px
            text-align center
            font-size 46px
            font-weight bold
            color rgba(255, 211, 93, 1)

        .two
          position absolute
          left 0
          bottom 0
          z-index 2
          width 226px
          height 346px

          .ico
            bg-image2('assets/activity-evaluation/two')
            background-size 100% 100%
            background-repeat no-repeat

          .avatar
            margin-bottom 24px

        .one
          position absolute
          left 222px
          top 0
          z-index 3
          width 226px
          height 100%

          .ico
            bg-image2('assets/activity-evaluation/one')
            background-size 100% 100%
            background-repeat no-repeat

          .avatar
            margin-bottom 64px

        .three
          position absolute
          right 0
          bottom 0
          z-index 1
          width 226px
          height 334px

          .ico
            bg-image2('assets/activity-evaluation/three')
            background-size 100% 100%
            background-repeat no-repeat

          .avatar
            margin-bottom 14px

      .table-title
        display flex
        align-items center
        width 670px
        height 104px
        margin 60px auto 20px auto
        font-size 30px
        color rgba(51, 51, 51, 1)
        border-radius 16px
        background rgba(255, 255, 255, 1)
        box-shadow 0 9px 51px 0 rgba(0, 0, 0, 0.04)

      .sort
        width 100px
        text-align center
        no-wrap()

      .user-name
        width 200px
        text-align center
        no-wrap()

      .good-num
        width 150px
        text-align center
        no-wrap()

      .create-time
        width 220px
        text-align center
        no-wrap()

      .table-item
        display flex
        align-items center
        margin 0 auto
        width 670px
        height 100px
        font-size 26px
        color rgba(106, 106, 106, 1)
        border-bottom 1px solid rgba(232, 233, 233, 1)

        .red
          color rgba(255, 52, 22, 1)
</style>
